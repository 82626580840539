'use client'
import { disableQuery, useQuery } from '@connectrpc/connect-query'
import { getSiteRating } from '@fingertip/creator-proto/gen/fingertip/creator/rating/v1/rating-RatingService_connectquery'
import { StarFilledIcon, StarIcon } from '@fingertip/icons'
import { type Session } from '@supabase/supabase-js'
import { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Spinner } from '@/components/shared/spinner'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useToken } from '@/lib/hooks/use-token'

import { RatingForm, Schema } from './rating-form'

type Props = {
  pageSlug: string
  siteSlug: string
  session: Session | null
  children?: ReactNode
}

export const RatingDialog = ({
  siteSlug,
  pageSlug,
  session,
  children,
}: Props) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const { hasFetched, callOptions } = useToken()

  const { data, error, isLoading } = useQuery(
    getSiteRating,
    !hasFetched
      ? disableQuery
      : {
          siteSlug,
        },
    {
      callOptions,
    },
  )

  const defaultValues = useMemo<Schema>(() => {
    return {
      rating: data?.rating?.rating ? String(data.rating.rating) : '',
      comment: data?.rating?.comment ?? '',
      email: session?.user?.email || '',
    }
  }, [data?.rating?.comment, data?.rating?.rating, session?.user?.email])

  if (error) {
    return null
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {children ? (
        <DialogTrigger asChild>{children}</DialogTrigger>
      ) : (
        <DialogTrigger title={t('rate')} className="px-3 pt-1.5">
          {data?.rating?.rating ? (
            <StarFilledIcon
              width={24}
              height={24}
              className="cursor-pointer text-primary"
            />
          ) : (
            <StarIcon className="size-6 cursor-pointer" />
          )}
        </DialogTrigger>
      )}

      <DialogContent>
        {data && (
          <>
            <DialogHeader>
              <DialogTitle>{t('leave_a_review')}</DialogTitle>
              <DialogDescription>{t('how_would_you_rate_t')}</DialogDescription>
            </DialogHeader>

            <RatingForm
              pageSlug={pageSlug}
              siteSlug={siteSlug}
              defaultValues={defaultValues}
              onSuccess={() => setOpen(false)}
            />
          </>
        )}

        {isLoading && <Spinner size={24} />}
      </DialogContent>
    </Dialog>
  )
}
