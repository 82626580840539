import React from 'react'

import { cn } from '@/lib/utils'

import { StylesProps } from '../page-footer.types'
import { FooterCta } from '../shared/footer-cta'
import { FooterDescription } from '../shared/footer-description'
import { FooterLogo } from '../shared/footer-logo'
import { FooterName } from '../shared/footer-name'
import { FooterSocialIcons } from '../shared/footer-social-icons'

export const BasePageFooter = ({
  page,
  isWhiteLabeled,
  forceMobile,
  ...props
}: StylesProps) => {
  return (
    <div className={cn({ 'lg:pb-fluid-10': !forceMobile })}>
      <div
        className={cn(
          'page-header-block ft-widget-wrapper relative rounded-b-none! text-page-text',
          { 'lg:rounded-page-widget!': !forceMobile },
        )}
      >
        <div className="p-block flex flex-col items-center space-y-fluid-4 pt-fluid-6!">
          <FooterLogo
            media={page?.logoMedia}
            {...props}
            className="shadow-page-widget rounded-page-widget-block size-fluid-12"
          />

          <FooterName
            name={page?.name}
            {...props}
            className="text-center text-fluid-base leading-tight text-page-heading-text"
          />

          <FooterDescription
            description={page?.description}
            {...props}
            className="text-center text-fluid-base leading-tight"
          />

          <FooterSocialIcons socialIcons={page?.socialIcons} {...props} />
        </div>

        {isWhiteLabeled ? (
          <div className="pb-fluid-4" />
        ) : (
          <>
            <div className="p-block flex justify-center">
              <FooterCta siteSlug={props.siteSlug} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
