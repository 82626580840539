import { PartialMessage } from '@bufbuild/protobuf'
import { HeaderImageStyle } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/header_image_style_pb'
import { Luminance } from '@fingertip/creator-proto/gen/fingertip/common/enum/v1/luminance_pb'
import { PageThemeSchema } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/page_theme_schema_pb'

export const FALLBACK_PAGE_THEME: PartialMessage<PageThemeSchema> = {
  css: '',
  html: '',
  media: {
    type: 'COLOR',
    color: '#fff',
    backgroundColor2: '',
  },
  version: 1,
  cardSlug: 'geex-one',
  iconSlug: 'geex-one',
  iconColor: '#4973d4',
  inputSlug: 'geex-one',
  luminance: Luminance.LIGHT,
  textColor: '#000',
  buttonSlug: 'geex-one',
  cardShadow: 'none',
  widgetSlug: 'geex-one',
  inputShadow: 'none',
  dividerColor: 'rgba(0, 0, 0, 0.5)',
  widgetShadow: 'none',
  bodyTextColor: '#0a2b23',
  cardIconColor: '#4973d4',
  cardTextColor: '#4973d4',
  iconBoxShadow: 'none',
  bodyFontFamily: 'Inter',
  bodyFontWeight: '400',
  cardBackground: 'rgba(255, 255, 255, 0.5)',
  inputIconColor: '#ffffff',
  inputTextColor: '#000',
  cardBorderColor: '',
  cardBorderWidth: 0,
  inputBackground: '#fff',
  cardBackdropBlur: '5px',
  headingTextColor: '#0a2b23',
  inputBorderColor: 'rgba(0, 0, 0, 0.5)',
  inputBorderWidth: 1,
  widgetBackground: 'rgba(255, 255, 255, 0.5)',
  headingFontFamily: 'Inter',
  headingFontWeight: '400',
  iconBoxBackground: 'rgba(255, 255, 255, 0.5)',
  widgetBorderColor: '',
  widgetBorderWidth: 0,
  bodyMutedTextColor: 'rgba(10, 43, 35, 0.7)',
  iconBoxBorderColor: '',
  iconBoxBorderWidth: 0,
  widgetBackdropBlur: '16px',
  widgetBorderRadius: 0,
  primaryButtonShadow: 'none',
  inputPlaceholderColor: 'rgba(34, 64, 135, 0.7)',
  secondaryButtonShadow: 'none',
  primaryButtonTextColor: '#f5f9fa',
  primaryButtonFontWeight: '400',
  cardSecondaryBackground: 'rgba(0, 0, 0, 0.4)',
  primaryButtonBackground: '#4973d4',
  primaryButtonBorderColor: '',
  primaryButtonBorderWidth: 0,
  secondaryButtonTextColor: '#4973d4',
  primaryButtonBackdropBlur: '5px',
  secondaryButtonBackground: 'rgba(255, 255, 255, 0.7)',
  secondaryButtonBorderColor: '',
  secondaryButtonBorderWidth: 0,
  secondaryButtonBackdropBlur: '5px',
  secondaryButtonFontWeight: '400',
  headerImageStyle: HeaderImageStyle.DEFAULT,
}

export const getDefaultPageTheme = (
  content?: PartialMessage<PageThemeSchema>,
): PartialMessage<PageThemeSchema> => {
  let defaultTheme = FALLBACK_PAGE_THEME

  if (!content) {
    return defaultTheme
  }

  const combinedConfig = {
    ...defaultTheme,
    ...Object.fromEntries(
      Object.entries(content).filter(
        ([, value]) => value !== null && value !== undefined && value !== '',
      ),
    ),
  }

  return combinedConfig
}
