import { GiphyFetch } from '@giphy/js-fetch-api'
import type { IGif } from '@giphy/js-types'
import NextImage from 'next/image'
import { CSSProperties, useEffect, useState } from 'react'

import { env } from '@/env'

const gf = new GiphyFetch(env.NEXT_PUBLIC_GIPHY_API_KEY)

type Props = {
  id: string
  color?: string
  height?: number
  width?: number
  className?: string
  style?: CSSProperties
  priority?: boolean
}

export const GiphyItem = ({
  id,
  width,
  height,
  className,
  style,
  priority,
}: Props) => {
  const [gif, setGif] = useState<IGif | null>(null)

  useEffect(() => {
    const attempt = async () => {
      // we know this is a video clip
      const { data } = await gf.gif(id)
      setGif(data)
    }

    attempt()
  }, [id])

  if (!gif) {
    return null
  }

  return (
    <NextImage
      src={gif.images?.downsized?.url}
      alt={gif?.alt_text || ''}
      width={width}
      height={height}
      className={className}
      style={style}
      priority={priority}
      placeholder="blur"
      blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN89x8AAuEB74Y0o2cAAAAASUVORK5CYII="
      unoptimized
    />
  )
}
