// NameComponent.tsx
import Link from 'next/link'
import React, { CSSProperties } from 'react'

import { ConditionalWrapper } from '@/components/shared/conditional-wrapper'
import { cn } from '@/lib/utils'

type Props = {
  name?: string | null
  className?: string
  style?: CSSProperties
  isPreview?: boolean
  href?: string
}

export const FooterName = ({
  name,
  className,
  style,
  isPreview,
  href,
}: Props) => {
  const fallbackName = isPreview ? 'Your page name' : null
  const newName = name || fallbackName

  if (!newName) {
    return null
  }

  return (
    <ConditionalWrapper
      condition={!!href}
      wrapper={(children) => (
        <Link href={href || ''} className="hover:underline underline-offset-2">
          {children}
        </Link>
      )}
    >
      <h1
        className={cn(
          'font-page-heading-weight font-page-heading text-fluid-4xl tracking-tight break-words w-full',
          className,
        )}
        style={style}
      >
        {newName}
      </h1>
    </ConditionalWrapper>
  )
}
