'use client'
import { LogoStandaloneIcon } from '@fingertip/icons'
import Link from 'next/link'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { clientGetUrl } from '@/lib/utils/client-get-url'

type Props = {
  siteSlug: string
}

export const FooterCta = ({ siteSlug }: Props) => {
  const { t } = useTranslation()

  const queryParam = queryString.stringify({
    utm_source: 'fingertip',
    utm_medium: 'site',
    utm_campaign: 'footer_cta',
    utm_content: siteSlug,
  })

  return (
    <Button asChild className="shadow-xs" size="blockSm">
      <Link href={`${clientGetUrl()}/onboarding?${queryParam}`} target="_blank">
        <LogoStandaloneIcon className="mr-fluid-1.5 w-[calc(19.2px*var(--scale-factor))] h-[calc(16px*var(--scale-factor))] shrink-0" />
        {t('fingertip_start_for_free')}
      </Link>
    </Button>
  )
}
