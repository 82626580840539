import { StarFilledIcon } from '@fingertip/icons'
import { UseFormRegister } from 'react-hook-form'

import { cn } from '@/lib/utils/cn'

import { Schema } from './rating-form'

type Props = {
  name: keyof Schema
  value: number
  register: UseFormRegister<Schema>
  rating: number | null
  hovering: boolean
  handlePointer: (rating: number | null) => void
}

export const RatingInput = ({
  name,
  value,
  register,
  rating,
  handlePointer,
}: Props) => {
  const isTrue = rating === null || rating < value
  return (
    <div>
      <input
        {...register(name)}
        type="radio"
        value={value}
        id={`${name}.${value}`}
        className="peer hidden"
      />
      <label
        htmlFor={`${name}.${value}`}
        className={cn(
          'inline-flex w-full cursor-pointer rounded-sm p-[6px] transition-all hover:bg-primary',
          {
            'bg-primary': !isTrue,
            'bg-neutral-400': isTrue,
          },
        )}
        onPointerEnter={() => handlePointer(value)}
        onPointerLeave={() => handlePointer(null)}
      >
        <StarFilledIcon className="size-5 text-white" />
      </label>
    </div>
  )
}
