// DescriptionComponent.tsx
import React, { CSSProperties } from 'react'

import { cn } from '@/lib/utils'

type Props = {
  description?: string | null
  className?: string
  style?: CSSProperties
  isPreview?: boolean
}

export const FooterDescription = ({
  description,
  className,
  style,
  isPreview,
}: Props) => {
  const fallbackDescription = isPreview ? 'A description about your page' : null
  const newDescription = description || fallbackDescription

  if (!newDescription) {
    return null
  }

  return (
    <p
      className={cn(
        'text-fluid-lg font-page-body font-page-body-weight break-words',
        className,
      )}
      style={style}
    >
      {newDescription}
    </p>
  )
}
