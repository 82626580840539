import { PartialMessage } from '@bufbuild/protobuf'
import { Media } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/media_pb'
import Link from 'next/link'
import { CSSProperties } from 'react'

import { MediaImage } from '@/components/blocks/shared/media-image'
import { ConditionalWrapper } from '@/components/shared/conditional-wrapper'
import { cn } from '@/lib/utils'

type Props = {
  media?: PartialMessage<Media> | null
  className?: string
  size?: number
  style?: CSSProperties
  isPreview?: boolean
  imageClassName?: string
  imageStyle?: CSSProperties
  href?: string
}

export const FooterLogo = ({
  media,
  className,
  size = 80,
  style,
  isPreview,
  imageClassName,
  imageStyle,
  href,
}: Props) => {
  const fallbackMedia = isPreview
    ? {
        type: 'UNSPLASH',
        color: '#0c2640',
        width: 3276,
        height: 4096,
        altText: 'woman wearing black crew-neck shirt',
        assetId: '',
        giphyId: '',
        assetSrc: '',
        giphyUrl: '',
        customUrl: '',
        unsplashId: '3TLl_97HNJo',
        assetBucket: '',
        unsplashUrl:
          'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixid=M3w1MjM0MDF8MHwxfHNlYXJjaHwxNnx8cHJvZmlsZXxlbnwwfHx8fDE3MDk2MjA2NTl8MA&ixlib=rb-4.0.3',
        positionZoom: '',
        assetFileName: '',
        brandfetchUrl: '',
        giphySearchTerm: '',
        unsplashSearchTerm: 'profile',
        brandfetchSearchTerm: '',
      }
    : null
  const newMedia = media || fallbackMedia

  if (!newMedia) {
    return null
  }

  return (
    <ConditionalWrapper
      condition={!!href}
      wrapper={(children) => <Link href={href || ''}>{children}</Link>}
    >
      <div
        className={cn('bg-page-widget-background overflow-hidden', className)}
        style={style}
      >
        {newMedia && (
          <MediaImage
            width={size * 1.5}
            height={size * 1.5}
            media={newMedia}
            priority={true}
            style={{ height: '100%', width: '100%', ...imageStyle }}
            className={imageClassName}
          />
        )}
      </div>
    </ConditionalWrapper>
  )
}
