import { PartialMessage } from '@bufbuild/protobuf'
import { SocialIcon as ProtoSocialIcon } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/social_icon_pb'
import { sentenceCase } from 'change-case'
import { CSSProperties } from 'react'

import { SocialIcon } from '@/components/shared-page/social-icon'
import { SocialKeys } from '@/lib/data/socials'
import { cn } from '@/lib/utils/cn'
import { socialUrl } from '@/lib/utils/utils'

type Props = {
  socialIcons?: PartialMessage<ProtoSocialIcon>[] | null
  className?: string
  style?: CSSProperties
  isPreview?: boolean
  type?: 'BOTH' | 'PRIMARY' | 'SECONDARY'
  primaryClassName?: string
  showPrimaryIcon?: boolean
  showPrimaryLabel?: boolean
  secondaryClassName?: string
  showSecondaryIcon?: boolean
  showSecondaryLabel?: boolean
}

export const FooterSocialIcons = ({
  socialIcons,
  className,
  style,
  isPreview,
  type = 'BOTH',
  primaryClassName,
  showPrimaryIcon = true,
  showPrimaryLabel = false,
  secondaryClassName,
  showSecondaryIcon = true,
  showSecondaryLabel = false,
}: Props) => {
  const fallbackSocialIcons = isPreview ? [] : []
  const newSocialIcons = (socialIcons || fallbackSocialIcons || [])
    .filter((item) => !!item.url)
    .filter((item) => {
      switch (type) {
        case 'PRIMARY':
          return item?.isCta
        case 'SECONDARY':
          return !item?.isCta
        default:
          return true
      }
    })

  if (!newSocialIcons || newSocialIcons.length === 0) {
    return null
  }

  return (
    <div
      style={style}
      className={cn('flex flex-wrap justify-center space-x-fluid-2', className)}
    >
      {newSocialIcons.map((item, index) => {
        const showLabel = item?.isCta ? showPrimaryLabel : showSecondaryLabel
        const showIcon = item?.isCta ? showPrimaryIcon : showSecondaryIcon

        return (
          <a
            key={index}
            target="_blank"
            href={socialUrl({
              websiteUrl: item?.url || '',
              key: item?.key || '',
            })}
            className={cn(
              'flex flex-wrap items-center justify-center space-x-fluid-2 text-fluid-base mb-fluid-2',
              item?.isCta ? primaryClassName : secondaryClassName,
            )}
            title={item?.title || sentenceCase(item?.key || '')}
          >
            {showIcon && (
              <SocialIcon
                kind={item?.key as SocialKeys}
                className="size-fluid-6 shrink-0 transition-all"
              />
            )}
            {showLabel && (
              <span>{item?.title || sentenceCase(item?.key || '')}</span>
            )}
          </a>
        )
      })}
    </div>
  )
}
