import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import {
  createSiteRatingPublic,
  getCustomerRatings,
  getSiteRating,
} from '@fingertip/creator-proto/gen/fingertip/creator/rating/v1/rating-RatingService_connectquery'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import * as z from 'zod'

import { TextareaField } from '@/components/fields/textarea-field'
import { Button } from '@/components/ui/button'
import { useToken } from '@/lib/hooks/use-token'

import { InputField } from '../fields/input-field'
import { RatingInput } from './rating-input'

const schema = z.object({
  rating: z.string().min(1, 'Rating is required'),
  comment: z.string().optional(),
  email: z.string().min(1, 'Email address is required'),
})

export type Schema = z.infer<typeof schema>

type Props = {
  pageSlug: string
  siteSlug: string
  defaultValues: Schema
  onSuccess?: () => void
  inputClassName?: string
  labelClassName?: string
  buttonVariant?: 'blockPrimary' | 'default'
}

export const RatingForm = ({
  pageSlug,
  siteSlug,
  defaultValues,
  onSuccess,
  labelClassName,
  inputClassName,
  buttonVariant = 'default',
}: Props) => {
  const { t } = useTranslation()
  const { callOptions } = useToken()
  const [hovering, setHovering] = useState(false)

  const queryClient = useQueryClient()

  const mutation = useMutation(createSiteRatingPublic, {
    callOptions,
    onSuccess: () => {
      toast.success(t('rating_submitted'))
      onSuccess?.()

      queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(getSiteRating, { siteSlug }),
      })

      queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(getCustomerRatings, {}),
      })
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const { register, handleSubmit, control, watch } = useForm<Schema>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(schema),
  })

  const watchRating = watch(`rating`)

  const [rating, setRating] = useState<number | null>(
    watchRating ? parseInt(watchRating) : 0,
  )

  useEffect(() => {
    if (watchRating) {
      setHovering(true)
      setRating(watchRating ? parseInt(watchRating) : 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  const handlePointer = useCallback(
    (rating: number | null) => {
      if (rating === null) {
        setHovering(true)
        setRating(watchRating ? parseInt(watchRating) : 0)
      } else {
        setHovering(false)
        setRating(rating)
      }
    },
    [watchRating],
  )

  const submitHandler = useCallback(
    async (input: Schema) => {
      if (!rating) {
        return
      }

      mutation.mutate({
        ...input,
        pageSlug,
        siteSlug,
        rating,
      })
    },
    [mutation, pageSlug, rating, siteSlug],
  )

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="mb-2 flex items-center space-x-1">
        {[...Array(5)].map((_, index) => (
          <RatingInput
            register={register}
            value={index + 1}
            name="rating"
            rating={rating}
            handlePointer={handlePointer}
            hovering={hovering}
            key={index}
          />
        ))}
      </div>

      <InputField
        control={control}
        name="email"
        label={t('email_address')}
        placeholder={t('enter_email_address')}
        inputClassName={inputClassName}
        labelClassName={labelClassName}
      />

      <TextareaField
        control={control}
        name="comment"
        label={t('your_review_optional')}
        placeholder={t('tell_us_more_about_your_experience')}
        labelClassName={labelClassName}
        minRows={2}
        inputClassName={inputClassName}
      />

      <div className="flex">
        <Button loading={mutation.isPending} variant={buttonVariant}>
          {t('post')}
        </Button>
      </div>
    </form>
  )
}
