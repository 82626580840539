'use client'
import { PartialMessage } from '@bufbuild/protobuf'
import { Page } from '@fingertip/creator-proto/gen/fingertip/common/type/v1/page_pb'
import { createElement, memo, ReactNode } from 'react'

import { BasePageFooter } from './styles/base'

export const footerSlugs: { id: string; label: string }[] = [
  { id: 'BASE', label: 'Visible' },
  { id: 'HIDDEN', label: 'Hidden' },
]

type Props = {
  page?: PartialMessage<Page>
  footerSlug?: string
  children?: ReactNode
  isPreview?: boolean
  isWhiteLabeled?: boolean
  isPageEditor?: boolean
  siteSlug: string
  domain?: string
  forceMobile?: boolean
}

export const PageFooter = memo(
  ({
    page,
    footerSlug = 'HIDDEN',
    children,
    isPreview,
    isWhiteLabeled,
    isPageEditor,
    domain,
    siteSlug,
    forceMobile,
  }: Props) => {
    const hasLogoImage = page?.logoMedia || isPreview

    const footerComponent = getFooterComponent(footerSlug)

    let content = children || null

    if (!isPageEditor && isWhiteLabeled) {
      content = <div className="pt-fluid-10 md:pt-0" />
    }

    if (footerSlug === 'HIDDEN') {
      return content
    }

    if (!page?.logoMedia && !page?.name && !page?.description && !isPreview) {
      return content
    }

    let href

    if (!isPreview) {
      href = domain ? `/` : `/${siteSlug}`
    }

    return createElement(footerComponent, {
      page,
      isPreview,
      footerComponent,
      hasLogoImage,
      isWhiteLabeled,
      href,
      siteSlug,
      forceMobile,
    })
  },
)

function getFooterComponent(footerSlug: string): any {
  switch (footerSlug) {
    default:
      return BasePageFooter
  }
}
